<template lang="pug">
    v-dialog(
        v-model='dialog'
        fullscreen
        scrollable
        transition='dialog-bottom-transition'
    )
        template(v-slot:activator='{ on, attrs }')
            v-btn.volie-color-blue(
                v-bind='attrs'
                v-on='on'
                dark
                ripple
                rounded
                outlined
                style="border-color: #0f5b9a;"
            )
                template(v-if='windowWidth >= 1250')
                    | {{ originDirection | titleize }} Calls Today: {{ (originCallsToday || 0) | number }}
                template(v-else)
                    template(v-if="originDirection === 'inbound'")
                        v-icon mdi-phone-incoming
                        span
                            | : {{ (originCallsToday || 0) | number }}
                    template(v-else-if="originDirection === 'outbound'")
                        v-icon mdi-phone-outgoing
                        span
                            | : {{ (originCallsToday || 0) | number }}

        v-card
            v-toolbar
                v-btn(icon @click='closeDialog();')
                    v-icon mdi-close
                v-toolbar-title Today's Call Log
                v-spacer
                div(style="width: 300px;")
                    v-text-field(dense @click='searchDialog = true' @click:append='() => searchDialog = true' @click:prepend-inner='() => searchDialog = true' prepend-inner-icon='mdi-magnify', append-icon='mdi-chevron-down', label='Search' single-line hide-details readonly outlined filled )
                v-dialog(v-model='searchDialog' persistent='' max-width='600px')
                    v-card
                        v-card-title
                            span.text-h5 Search Calls
                        v-card-text
                            v-container
                                v-row
                                    v-col(cols='12')
                                        v-autocomplete(v-model='searchObj.organizationIds' multiple :items='organizationUsers' label='Organization' item-text="organization_name" item-value="organization_id" clearable)
                                            template(v-slot:selection='{ item, index }')
                                                v-chip(v-if='index === 0')
                                                    span {{ item.organization_name }}
                                                span.grey--text.caption(v-if='index === 1')
                                                    | (+{{ searchObj.organizationIds.length - 1 }} others)

                                v-row
                                    v-col(cols="12")
                                        v-text-field(label='Campaign' type='text' v-model="searchObj.campaignName")
                                    
                                v-row
                                    v-col(cols='12')
                                        v-select(
                                            v-model='searchObj.direction'
                                            :items='directions'
                                            label='Direction'
                                            item-text="key"
                                            item-value="value"
                                        )
                                v-row
                                    v-col(cols="12")
                                        v-text-field(label='Disposition' type='text' v-model="searchObj.dispositionDescription")

                        v-card-actions
                            v-spacer
                                v-btn.ma-2(color="#0f5b9a" outlined ripple @click='searchDialog = false;')
                                    v-icon mdi-window-close
                                    | Close
                                v-btn.ma-2(color="#0f5b9a" outlined ripple @click='searchDialog = false; reinitSearch();')
                                    v-icon mdi-refresh
                                    | Reset
                                v-btn.ma-2(color="#0f5b9a" outlined ripple @click='searchDialog = false; searchCalls();')
                                    v-icon mdi-magnify
                                    | Search

            v-card-text.pa-md-4.brown.lighten-5(style="height: 100vh;")
                .text-center(v-if="isLoading")
                    v-progress-circular(
                        :size='100'
                        :width='10'
                        color='#0f5b9a'
                        indeterminate
                        style="margin-top: 100px;"
                    )

                v-card(v-else)
                    v-card-text
                        v-simple-table.ma-md-4.table-x-scroll(v-if="validCalls")
                            template(v-slot:default)
                                thead
                                    tr
                                        th
                                        th Time
                                        th Direction
                                        th Organization
                                        th Campaign
                                        th Customer
                                        th Disposition
                                        th To Number
                                        th From Number
                                        th Duration
                                tbody
                                    tr(
                                        v-for='(call, idx) in calls'
                                        :key='call.call_id'
                                    )
                                        td.no-wrap.xs-xs-width {{ idx + 1 | number }}
                                        td.no-wrap.sm-width
                                            template(v-if="!!call.start_time_time") {{ call.start_time_time }}
                                            template(v-else) PROCESSING
                                        td.no-wrap.sm-width
                                            template(v-if="call.direction === '1'") Inbound
                                            template(v-if="call.direction === '2'") Outbound
                                        td.no-wrap.sm-width
                                            v-tooltip(top)
                                                template(v-slot:activator='{ on, attrs }')
                                                    span(v-bind="attrs" v-on="on" role="text")
                                                        | {{ call.organization_name | str_limit(35) }}
                                                span
                                                    | {{ call.organization_name }}
                                        td.no-wrap.sm-width
                                            v-tooltip(top)
                                                template(v-slot:activator='{ on, attrs }')
                                                    span(v-bind="attrs" v-on="on" role="text")
                                                        | {{ call.campaign_name | str_limit(35) }}
                                                span
                                                    | {{ call.campaign_name }}
                                        td.no-wrap.sm-width
                                            template(v-if="call.customer_full_name")
                                                v-tooltip(top)
                                                    template(v-slot:activator='{ on, attrs }')
                                                        span(v-bind="attrs" v-on="on" role="text")
                                                            | {{ call.customer_full_name | str_limit(35) }}
                                                    span
                                                        | {{ call.customer_full_name }}
                                            template(v-else) ---
                                        td.no-wrap.sm-width
                                            template(v-if="!!call.disposition_description")
                                                v-tooltip(top)
                                                    template(v-slot:activator='{ on, attrs }')
                                                        span(v-bind="attrs" v-on="on" role="text")
                                                            | {{ call.disposition_description | str_limit(50) }}
                                                    span
                                                        | {{ call.disposition_description }}
                                            template(v-else) ---
                                        td.no-wrap.sm-width {{ call.friendly_to_number | tel }} 
                                        td.no-wrap.sm-width {{ call.friendly_from_number | tel }} 
                                        td.no-wrap.sm-width {{ (call.agent_talk_duration || 0) | secondsToFullWords }}

                                    tr(v-if="showMore")
                                        td.text-center(colspan='10')
                                            v-btn.volie-color-blue(link text ripple small @click="getCalls();")
                                                | Show more

                        v-alert.mx-lg-auto.mt-md-4.mb-md-4(type="warning" v-else)
                            | Unable to find any calls

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Data
    import { GetCalls, GetOrganizationUsers } from "@/data";

    export default Vue.extend({
        data() {
            return {
                calls: [],
                dialog: false,
                directions: [
                    { key: "Both", value: "" },
                    { key: "Inbound", value: "inbound" },
                    { key: "Outbound", value: "outbound" },
                ],
                isLoading: false,
                lastConferenceID: null,
                limit: 100,
                organizationUsers: [],
                searchDialog: false,
                searchObj: {},
                windowWidth: 0,
            }
        },
        methods: {
            closeDialog() {
                this.searchDialog = false;
                this.dialog = false;
            },
            currentDateString() {
                const d = new Date();
                return d?.toLocaleDateString();
            },
            getCalls() {

                // We only want to show current day of calls in this dialog
                const startEnd = this.currentDateString();

                // Init
                const options = {
                    campaign_name: this.searchObj?.campaignName,
                    disposition_description: this.searchObj?.dispositionDescription,
                    end_date: startEnd,
                    friendly_direction: this.searchObj?.direction,
                    last_conference_id: (this.lastConferenceID || 0),
                    limit: this.limit,
                    organization_ids: this.searchObj?.organizationIds,
                    sort_by_column_name: 'created_at',
                    sort_by_direction: 'desc',
                    start_date: startEnd,
                    user_id: (this.currentUser?.user_id || 0),
                }

                GetCalls(options).then((response) => {
                    if (response?.Data?.calls?.length > 0) {
                        if (!!this.calls && this.calls.length > 0) {
                            this.calls = this.calls.concat(response.Data.calls);
                        } else {
                            this.calls = response.Data.calls;
                        }

                        // Get last record and set ID
                        this.lastConferenceID = this.calls[this.calls.length - 1]?.conference_id;
                    }

                    this.isLoading = false;
                });
            },
            getOrganizationUsers() {
                GetOrganizationUsers().then((response) => {
                    if (!!response.Data && !!response.Data.organization_users && response.Data.organization_users.length > 0) {
                        this.organizationUsers = response.Data.organization_users;
                    }
                });
            },
            handleWindowResize() {
                if (window?.innerWidth > 0) {
                    this.windowWidth = window.innerWidth;
                    return;
                }

                this.windowWidth = 0;
            },
            reinitSearch() {
                this.resetSearchObj();
                this.searchCalls();
            },
            resetSearchObj() {
                this.searchObj = {
                    campaignName: null,
                    direction: this.originDirection,
                    dispositionDescription: null,
                    organizationIds: [],
                }
            },
            searchCalls() {
                this.isLoading = true;
                this.calls = [];
                this.lastConferenceID = null;
                this.getCalls();
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            showMore() {
                return (this.calls?.length > 0 && this.calls.length % this.limit === 0);
            },
            validCalls() {
                return this.calls?.length > 0;
            },
        },
        components: {
        },
        props: {
            originCallsToday: Number,
            originDirection: String,
        },
        watch: {
            dialog() {
                // Load only as dialog is opened
                if (this.dialog) {
                    this.getOrganizationUsers();
                    this.reinitSearch();
                }
            },
        },
        created() {
            this.handleWindowResize();
            window.addEventListener('resize', this.handleWindowResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
        },
    });
</script>

<style lang="scss" scoped>
</style>
