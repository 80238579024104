<template lang="pug">
    div
        v-container
            v-form
                v-row(align="center")
                    v-spacer
                    v-col(cols='9')
                            v-text-field(label='Vehicle VIN' type='text' v-model="vehicleVin" @keydown.enter="search()")

                v-row(align="center")
                    v-col(cols='12')
                        .text-right
                            v-btn.mr-4(
                                @click='resetSearch();'
                            )
                                v-icon mdi-refresh
                                | Reset
                            v-btn.mr-4(
                                color='info'
                                @click='search();'
                                :disabled="disableSearchButton"
                            )
                                v-icon mdi-magnify
                                | Search

        div(
           v-if="validResults"
        )
            CustomerVehicleToyoguardDetails(
                v-if="results.vid.length > 0"
                :toyoguard-program="results"
            )



        v-alert(
            v-if="!validResults && isSearched"
            type='info'
            border='left'
        )
            | Unable to find any results. Please adjust your search criteria.

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import CustomerVehicleToyoguardDetails from '@/components/customer_vehicles/toyoguard_details.vue';

    // Data
    import { SearchToyoguardVehicles } from "@/data";

    // Helpers
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                results: [],
                isSearched: false,
                vehicleVin: null,
            }
        },
        methods: {
            vehicleYearMakeModel(result) {

                const vehObj = {
                    vehicleModelYear: result?.vehicle?.vehicle_model_year,
                    vehicleMake:      result?.vehicle?.vehicle_make,
                    vehicleModel:     result?.vehicle?.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
            resetSearch() {
                this.isSearched = false;
                this.results = [];
                this.vehicleVin = null;
            },
            search() {

                // Init
                const options = {
                    vehicle_vin: this.vehicleVin,
                }

                this.isLoading = true;
                SearchToyoguardVehicles(options).then((response) => {
                    if (response?.Data?.toyoguard_program?.vid?.length > 0) {
                    this.results = response.Data.toyoguard_program;
                    } else {
                        this.results = [];
                    }
                    this.isSearched = true;
                    this.isLoading = false;
                });
            },
        },
        computed: {

            disableSearchButton() {

                // Vehicle vin
                if (this.vehicleVin?.length === 17) {
                    return false;
                }

                return true;
            },
            validResults() {
                return this.results?.vid?.length > 0;
            },

        },
        components: {
            CustomerVehicleToyoguardDetails,
        },
        props: {
        },
        destroyed() {
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
</style>
