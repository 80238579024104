<template lang="pug">
    div(v-if="currentUser")
        
        v-alert(dense outlined type='error' v-if="!validCustomer") In order to reschedule an appointment, a valid customer must be present.

        v-alert(
            v-else-if="!validUpcomingAppointments"
            border="left"
            dense
            type="warning"
        )
            | No upcoming appointments

        div(v-else)

            AppointmentForm(
                v-if="validRescheduleAppointment"
                :on-appointment-change="onRescheduleAppointmentChange"
                :reschedule-appointment="rescheduleAppointment"
                :use-on-change="true"
                :time-zone-id="timeZoneId"
                :valid-customer="validCustomer"
            )

            v-card.mx-auto.mb-sm-4(
                v-else-if="renderUpcomingAppointments"
                v-for="(appt, idx) in upcomingAppointments"
                :key="idx"
                outlined
            )
                template
                    v-list(two-line)
                        template
                            v-list-item
                                v-list-item-content
                                    v-list-item-title {{ appt.organization_name }}
                                    v-list-item-subtitle.text--primary(v-text='appt.friendly_appointment_datetime')
                                    v-list-item-subtitle.text--primary(
                                        v-if="appt.appointment_type_id > 0"
                                        v-text='appt.appointment_type_name'
                                    )
                                    v-list-item-subtitle.text--primary(
                                        v-if="validVehicle(appt)"
                                        v-text='appt.vehicle_year_make_model'
                                    )
                                v-list-item-action
                                    v-btn(outlined fab x-small color="green" @click="selectAppointment(appt);")
                                        v-icon
                                            | mdi-check


</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import AppointmentForm from '@/components/appointments/form.vue';

    export default Vue.extend({
        data() {
            return {
                originalAppointmentDate: null,
                originalAppointmentTime: null,
                rescheduleAppointment: null,
                selectedUpcomingAppointment: null,
            }
        },
        methods: {
            initRescheduleAppointment() {

                // Verify
                if (!(this.selectedUpcomingAppointment?.appointment_id > 0)) {
                    return;
                }

                // Set date so it is pre-populated to previous
                const datePieces = this.selectedUpcomingAppointment.appointment_datetime.split("T");
                if (datePieces?.length > 0) {
                    this.originalAppointmentDate = datePieces[0];
                }

                // Set time so it is pre-populated to previous
                const rawHour = parseInt(this.selectedUpcomingAppointment.appointment_hour);
                let amPmHourOffset = this.selectedUpcomingAppointment.appointment_am_pm === "PM" ? 12 : 0;

                // Determine if we need to offset to account for 24 hour clock
                if (this.selectedUpcomingAppointment.appointment_am_pm === "PM") {
                    amPmHourOffset = rawHour === 12 ? 0 : 12;
                } else {
                    amPmHourOffset = rawHour === 12 ? -12 : 0;   
                }

                // Set original time
                this.originalAppointmentTime = (rawHour + amPmHourOffset).toString() + ":" + this.selectedUpcomingAppointment.appointment_minute;

                // Init
                this.rescheduleAppointment = {
                    appointment_id: this.selectedUpcomingAppointment.appointment_id,
                    appointment_datetime_date: this.originalAppointmentDate,
                    appointment_datetime_time: this.originalAppointmentTime,
                    appointment_type_id: this.selectedUpcomingAppointment.appointment_type_id,
                    attempt_key: this.currentAgent?.attempt_key,
                    customer_phone_number: this.selectedUpcomingAppointment.customer_phone_number,
                    datetime_changed: false,
                    delivery_address: this.selectedUpcomingAppointment.delivery_address,
                    delivery_location: this.selectedUpcomingAppointment.delivery_location,
                    demo_vehicle: this.selectedUpcomingAppointment.demo_vehicle,
                    license_plate: this.selectedUpcomingAppointment.license_plate,
                    special_instructions: this.selectedUpcomingAppointment.special_instructions,
                    time_zone_id: this.timeZoneId,
                    vehicle_access: this.selectedUpcomingAppointment.vehicle_access,
                }
            },
            onRescheduleAppointmentChange(appt) {

                // Verify
                if (!appt) {
                    return;
                }

                // Set details on reschedule
                this.rescheduleAppointment.appointment_datetime_date = appt.appointment_datetime_date;
                this.rescheduleAppointment.appointment_datetime_time = appt.appointment_datetime_time;
                this.rescheduleAppointment.appointment_type_id = appt.appointment_type_id;
                this.rescheduleAppointment.special_instructions = appt.special_instructions;
                this.rescheduleAppointment.time_zone_id = appt.time_zone_id;
                this.rescheduleAppointment.customer_phone_number = appt.customer_phone_number;
                this.rescheduleAppointment.delivery_address = appt.delivery_address;
                this.rescheduleAppointment.delivery_location = appt.delivery_location;
                this.rescheduleAppointment.demo_vehicle = appt.demo_vehicle;
                this.rescheduleAppointment.license_plate = appt.license_plate;
                this.rescheduleAppointment.vehicle_access = appt.vehicle_access;

                // Determine if datetime changed
                this.rescheduleAppointment.datetime_changed = (this.rescheduleAppointment.appointment_datetime_date !== this.originalAppointmentDate || this.rescheduleAppointment.appointment_datetime_time !== this.originalAppointmentTime) ? true : false;

                // Bubble up to disposition component
                this.onAppointmentChange(this.rescheduleAppointment);
            },
            selectAppointment(appt) {
                if (!(appt?.appointment_id > 0)) {
                    return;
                }

                // Set selected
                this.selectedUpcomingAppointment = appt;

                // Init reschedule
                this.initRescheduleAppointment();
            },
            validVehicle(appt) {
                return appt?.customer_vehicle_key?.length > 0 && appt?.vehicle_year_make_model?.length > 0;
            },
        },
        computed: {
            currentAgent() {
                return VolieState.currentAgent();
            },
            currentUser() {
                return VolieState.currentUser();
            },
            renderUpcomingAppointments() {
                return !this.validSelectedUpcomingAppointment;
            },
            validRescheduleAppointment() {
                return this.rescheduleAppointment?.appointment_id > 0 && this.rescheduleAppointment?.appointment_id === this.selectedUpcomingAppointment?.appointment_id;
            },
            validSelectedUpcomingAppointment() {
                return this.selectedUpcomingAppointment?.appointment_id > 0;
            },
            validUpcomingAppointments() {
                return this.upcomingAppointments?.length > 0;
            },
        },
        components: {
            AppointmentForm,
        },
        props: {
            onAppointmentChange: Function,
            timeZoneId: Number,
            upcomingAppointments: Array,
            validCustomer: Boolean,
        },
        watch: {
            appointment() {
                this.onChange();
            },
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
</style> 
