<template lang="pug">
    v-app-bar(app clipped-left color="#F5F5F5" light fixed=true)
        .d-flex.align-center
            a(link :href="railsVolieBaseUrl")
                template(v-if="verifyCurrentUser && currentUser.activated_account_id === 1516")
                    v-img.shrink.mr-2(
                        v-if='windowWidth >= 1000'
                        alt="Volie Logo"
                        class="shrink mr-2"
                        contain
                        src="https://volie-assets.s3.amazonaws.com/system/bdc-central-144x45px.png"
                        transition="scale-transition"
                        width="144"
                    )
                    v-img.shrink.mr-2(
                        v-else
                        alt="Volie Logo"
                        class="shrink mr-2"
                        contain
                        src="https://volie-assets.s3.amazonaws.com/system/bdc-central-hex-45x45px.png"
                        transition="scale-transition"
                        width="45"
                    )

                template(v-else)
                    v-img.shrink.mr-2(
                        v-if='windowWidth >= 1000'
                        alt="Volie Logo"
                        class="shrink mr-2"
                        contain
                        :src="mainLogoUrl"
                        transition="scale-transition"
                        width="144"
                    )
                    v-img.shrink.mr-2(
                        v-else
                        alt="Volie Logo"
                        class="shrink mr-2"
                        contain
                        :src="mainIconUrl"
                        transition="scale-transition"
                        width="45"
                    )

        v-spacer

        template(v-if="verifyCurrentUser")
            CurrentAgent
            span.mr-5

            template(v-if='windowWidth >= 600')
                ConferenceDayLog(
                    :origin-calls-today="inboundCallsToday"
                    origin-direction="inbound"
                )
                span.mr-5

                ConferenceDayLog(
                    :origin-calls-today="outboundCallsToday"
                    origin-direction="outbound"
                )
                span.mr-5

            template(v-if='windowWidth >= 750')
                CurrentUserClock(
                    :show-timezone-abbreviation="windowWidth >= 1000"
                )
                span.mr-5

            v-menu(offset-y min-width='200')
                template(v-slot:activator='{ on }')
                    v-btn.volie-color-blue(dark v-on='on' ripple rounded outlined style="border-color: #0f5b9a;")
                        template(v-if='windowWidth >= 1350')
                            | {{ currentUser.full_name }}
                        template(v-else)
                            v-icon mdi-account
                        v-icon mdi-menu-down
                v-card
                    v-list
                        v-subheader(v-if='windowWidth < 1350')
                            | {{ currentUser.full_name }}
                        v-list-item(ripple link :href="railsVolieBaseUrl + '/users/' + currentUser.user_key + '/edit'")
                            v-list-item-action
                                v-icon mdi-account
                            v-list-item-content
                                v-list-item-title Profile

                        v-list-item(ripple link href='/auth/ab/logout')
                            v-list-item-action
                                v-icon mdi-logout
                            v-list-item-content
                                v-list-item-title Logout

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';
    import ConferenceDayLog from '@/components/conferences/dialog_day_log.vue';
    import CurrentAgent from './current_agent.vue';
    import CurrentUserClock from './current_user_clock.vue';

	export default Vue.extend({
        data() {
            return {
                items: [
                    {
                        icon: 'mdi-account',
                        title: 'Profile',
                        to: "",
                    },
                    {
                        icon: 'mdi-logout',
                        title: 'Logout',
                    },
                ],
                windowWidth: 0,
            }
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            currentTime() {
                return VolieState.currentTime();
            },
            inboundCallsToday() {
                return this.currentUser?.account_user?.inbound_calls_today || 0;
            },
            outboundCallsToday() {
                return this.currentUser?.account_user?.outbound_calls_today || 0;
            },
            railsVolieBaseUrl() {
                return VolieState.baseRailsUrl();
            },
            verifyCurrentUser() {
                const currentUser = this.currentUser;
                return (!!currentUser && currentUser.user_id > 0 && currentUser.activated_account_id > 0 && currentUser.activated_role_id > 0);
            },
            whiteLabel() {
                return VolieState.whiteLabel();
            },
            mainIconUrl() {
                if (this.whiteLabel?.main_icon_url?.length > 0) {
                    return this.whiteLabel.main_icon_url;
                }

                return "https://s3.amazonaws.com/switch-telephony-misc/volie_logos_2/favicon_45.png";
            },
            mainLogoUrl() {
                if (this.whiteLabel?.main_logo_url?.length > 0) {
                    return this.whiteLabel.main_logo_url;
                }

                return "https://s3.amazonaws.com/switch-telephony-misc/volie_logos_2/Volie_144x45px.png";
            },
        },
        methods: {
            handleWindowResize() {
                if (window?.innerWidth > 0) {
                    this.windowWidth = window.innerWidth;
                    return;
                }

                this.windowWidth = 0;
            },
        },
        components: {
            ConferenceDayLog,
            CurrentAgent,
            CurrentUserClock,
        },
        created() {
            this.handleWindowResize();
            window.addEventListener('resize', this.handleWindowResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
        },
	});
</script>

<style lang="scss" scoped>
</style>
