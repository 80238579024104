<template lang="pug">
    .ma-sm-2

        SwitchCampaign(
            v-if="switchCampaignDialog"
            :current-campaign-customer="currentCampaignCustomer"
            :close-switch-campaign="closeSwitchCampaign"
            :reassign-campaign-customer-campaign="reassignCampaignCustomerCampaign"
        )

        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        template(v-if="validCommunicationManager")
            EmailClient(
                v-if="validEmailCustomerEmailAddress"
                v-bind:style="{ height: parentHeight + 'px' }"
                :account-id="emailCustomerEmailAddress.account_id"
                :audio-email-incoming="audioEmailIncoming"
                :audio-email-outcoming="audioEmailOutcoming"
                :client-height="parentHeight"
                :customer-id="emailCustomerEmailAddress.customer_id"
                :customer-email-address-id="emailCustomerEmailAddress.customer_email_address_id"
                :exit="exitEmailCustomerEmailAddress"
                :is-rac-online="isRacOnline"
                :other-campaign-customer-id="currentCampaignCustomerID"
                :show-snackbar="showSnackbar"
            )

            MessageSoftphone(
                v-else-if="validMessageCustomerPhoneNumber"
                v-bind:style="{ height: parentHeight + 'px' }"
                :account-id="messageCustomerPhoneNumber.account_id"
                :campaign-id="campaignID"
                :customer-id="communicationManager.customer_id"
                :customer-phone-number-id="messageCustomerPhoneNumber.customer_phone_number_id"
                :exit="exitMessageCustomerPhoneNumber"
                :incoming-phone-number-id="outputMessageIncomingPhoneNumberID"
                :other-campaign-customer-id="currentCampaignCustomerID"
                :show-snackbar="showSnackbar"
                :soft-phone-height="parentHeight"
            )

            template(v-else)
                v-list(two-line)
                    template
                        v-list-item
                            v-list-item-content
                                v-list-item-title.font-weight-bold.list-item-title-overflow
                                    CopyableText(:text="communicationManager.organization_name")
                                v-list-item-subtitle.text--primary.list-item-title-overflow(v-if="campaignName && campaignName.length > 0")
                                    CopyableText(:text="campaignName")
                                v-list-item-subtitle.text--primary.list-item-title-overflow(
                                    v-if="attemptDetails.length > 0"
                                    v-text="attemptDetails"
                                )
                            v-list-item-action(v-if="renderSwitchCampaignsButton || renderXtimeLink")
                                v-tooltip(v-if="renderSwitchCampaignsButton" bottom)
                                    template(v-slot:activator='{ on, attrs }')
                                        v-btn(v-bind='attrs' v-on='on' @click="openSwitchCampaignDialog();" color="primary" fab x-small)
                                            v-icon(dark) mdi-cog
                                    span Change Campaign
                                XtimeLinkForm(
                                    v-if="renderXtimeLink"
                                    :xtime-link="xtimeLink"
                                    :style="{ paddingTop: renderSwitchCampaignsButton ? '10px' : '0;' }"
                                )

                    template
                        v-divider(inset)
                        v-list-item
                            v-list-item-icon
                                v-icon(color='primary')
                                    | mdi-account
                            v-list-item-content
                                v-list-item-title.list-item-title-overflow 
                                    CopyableText(:text="customerName")
                                v-list-item-subtitle Name
                            v-list-item-action
                                CustomerGeneralInfoFormDialog(
                                    v-if="!hideCreateUpdateDestroyButtons"
                                    :first-name="communicationManager.first_name"
                                    :middle-name="communicationManager.middle_name"
                                    :last-name="communicationManager.last_name"
                                    :company-name="communicationManager.company_name"
                                    :birth-date="communicationManager.birth_date"
                                    :update-customer-communication-manager="updateCustomerCommunicationManager"
                                )

                        v-list-item
                            v-list-item-icon
                            v-list-item-content
                                v-list-item-title.list-item-title-overflow
                                v-list-item-title(v-if="communicationManager.company_name && communicationManager.company_name.length > 0")
                                    CopyableText(:text="communicationManager.company_name")
                                v-list-item-title(v-else) N/a
                                v-list-item-subtitle Company
                        v-list-item
                            v-list-item-icon
                            v-list-item-content
                                v-list-item-title(v-if="communicationManager.birth_date && communicationManager.birth_date.length > 0") 
                                    CopyableText(:text="communicationManager.birth_date")
                                v-list-item-title(v-else) N/a
                                v-list-item-subtitle Birthday
                        v-list-item(v-if="communicationManager.age && communicationManager.age > 0")
                            v-list-item-icon
                            v-list-item-content
                                v-list-item-title
                                    CopyableText(:text="communicationManager.age + ' years old'")
                                v-list-item-subtitle Age

                    CustomerAddresses(
                        :customer-addresses="communicationManager.customer_addresses"
                        :create-customer-address="createCustomerAddress"
                        :destroy-customer-address="destroyCustomerAddress"
                        :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                        :primary-customer-address-id="communicationManager.primary_address_id"
                        :update-customer-address="updateCustomerAddress"
                        :update-customer-address-primary="updateCustomerAddressPrimary"
                    )

                    CustomerEmailAddresses(
                        :customer-email-addresses="communicationManager.customer_email_addresses"
                        :create-customer-email-address="createCustomerEmailAddress"
                        :destroy-customer-email-address="destroyCustomerEmailAddress"
                        :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                        :primary-customer-email-address-id="communicationManager.primary_email_address_id"
                        :set-email-customer-email-address="setEmailCustomerEmailAddress"
                        :update-customer-email-address="updateCustomerEmailAddress"
                        :update-customer-email-address-primary="updateCustomerEmailAddressPrimary"
                    )

                    CustomerPhoneNumbers(
                        :current-campaign-customer="currentCampaignCustomer"
                        :customer-phone-numbers="communicationManager.customer_phone_numbers"
                        :create-customer-phone-number="createCustomerPhoneNumber"
                        :destroy-customer-phone-number="destroyCustomerPhoneNumber"
                        :customer-cell-phone-id="communicationManager.cell_phone_id"
                        :customer-home-phone-id="communicationManager.home_phone_id"
                        :customer-work-phone-id="communicationManager.work_phone_id"
                        :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                        :set-message-customer-phone-number="setMessageCustomerPhoneNumber"
                        :update-customer-phone-number="updateCustomerPhoneNumber"
                        :update-customer-phone-primary="updateCustomerPhonePrimary"
                    )

                    CommunicationPreferences(
                        :communication-manager="communicationManager"
                        :create-opt-in-out="createOptInOut"
                        :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                        :in-communication-center="inCommunicationCenter"
                    )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import CommunicationPreferences from '@/components/communication_center/shared/tabs/communication_preferences.vue';
    import CopyableText from '@/components/shared/copyable_text.vue';
    import CustomerAddresses from '@/components/communication_center/shared/tabs/customer_addresses.vue';
    import CustomerEmailAddresses from '@/components/communication_center/shared/tabs/customer_email_addresses.vue';
    import CustomerGeneralInfoFormDialog from '@/components/customer_general_info/form_dialog.vue';
    import CustomerPhoneNumbers from '@/components/communication_center/shared/tabs/customer_phone_numbers.vue';
    import EmailClient from '@/components/communication_center/email/email_client.vue';
    import MessageSoftphone from '@/components/communication_center/sms_message/softphone.vue';
    import SwitchCampaign from '@/components/communication_center/shared/switch_campaign.vue';
    import XtimeLinkForm from '@/components/xtime_links/form.vue';

    // Data
    import {
        CreateCustomerAddress,
        CreateCustomerEmailAddress,
        CreateOptInOut,
        CreateCustomerPhoneNumber,
        DestroyCustomerAddress,
        DestroyCustomerEmailAddress,
        DestroyCustomerPhoneNumber,
        GetCustomerCommunicationManager,
        GetXtimeLink,
        UpdateCustomerAddress,
        UpdateCustomerAddressPrimary,
        UpdateCustomerCommunicationManager,
        UpdateCustomerEmailAddress,
        UpdateCustomerEmailAddressPrimary,
        UpdateCustomerPhoneNumber,
        UpdateCustomerPhonePrimary,
    } from "@/data";

    // Helpers
    import { capitalizeString, numberToOrdinal } from "@/helpers/general";
    import { formatName } from "@/helpers/name";
    import { formatPhoneNumber } from "@/helpers/phone_number";

    export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                ccExpirationCodeNeverEligible: 11,
                communicationManager: null,
                emailCustomerEmailAddress: null,
                isLoading: false,
                messageCustomerPhoneNumber: null,
                messageIncomingPhoneNumber: null,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
                switchCampaignDialog: false,
                xtimeLink: null,
            }
        },
        methods: {
            closeSwitchCampaign() {
                this.switchCampaignDialog = false;
            },
            createCustomerAddress(options) {

                // Verify valid current campaign customer
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                // Set on options
                options.customer_id     = this.currentCampaignCustomer.customer_id;
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;

                CreateCustomerAddress(options).then((response) => {
                    if (response?.Data?.customer_address?.customer_address_id > 0) {
                        this.communicationManager.customer_addresses.push(response?.Data?.customer_address);
                        this.communicationManager.primary_address_id = response?.Data?.primary_address_id;
                        this.showSnackbar("green", "Successfully created address");
                    } else {
                        this.showSnackbar("red", "Failed to create address");
                    }
                });
            },
            createCustomerEmailAddress(options) {

                // Verify valid current campaign customer
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                // Set on options
                options.customer_id     = this.currentCampaignCustomer.customer_id;
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;

                CreateCustomerEmailAddress(options).then((response) => {
                    if (response?.Data?.customer_email_address?.customer_email_address_id > 0) {
                        this.communicationManager.customer_email_addresses.push(response?.Data?.customer_email_address);
                        this.communicationManager.primary_email_address_id = response?.Data?.primary_email_address_id;
                        this.showSnackbar("green", "Successfully created email address");
                    } else {
                        this.showSnackbar("red", "Failed to create email address");
                    }
                });
            },
            createOptInOut(options) {

                // Verify valid current campaign customer for in communicatino center and valid customer otherwise
                // Note - this might have to change when supporting contact opt out
                if ((this.inCommunicationCenter && !(this.currentCampaignCustomer?.campaign_customer_id > 0)) || (!this.inCommunicationCenter && !(options.optable_id > 0 && options.optable_type === "customer"))) {
                    return;
                }

                // Set on options
                options.campaign_customer_id = (this.currentCampaignCustomer.campaign_customer_id || 0);

                // todo
                // eventable_id
                // eventable_type

                CreateOptInOut(options).then((response) => {
                    if (response?.Data?.opt_in_out?.id > 0) {
                        this.showSnackbar("green", "Successfully updated communication preference");

                        // Set changes on communication manager
                        this.setSubscriptionChangesOnCommunicationManager(response);
                    }
                });
            },
            createCustomerPhoneNumber(options) {

                // Verify valid current campaign customer
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                // Set on options
                options.customer_id     = this.currentCampaignCustomer.customer_id;
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;

                CreateCustomerPhoneNumber(options).then((response) => {
                    if (response?.Data?.customer_phone_number?.customer_phone_number_id > 0) {
                        this.communicationManager.customer_phone_numbers.push(response?.Data?.customer_phone_number)
                        this.communicationManager.cell_phone_id = response?.Data?.cell_phone_id;
                        this.communicationManager.home_phone_id = response?.Data?.home_phone_id;
                        this.communicationManager.work_phone_id = response?.Data?.work_phone_id;
                        this.showSnackbar("green", "Successfully created phone number");
                    } else if (response?.Error?.length > 0) {
                        this.showSnackbar("red", response.Error);
                    } else {
                        this.showSnackbar("red", "Failed to create phone number");
                    }
                });
            },
            destroyCustomerAddress(options) {

                // Verify valid
                if (!(options?.customer_address_id > 0)) {
                    return;
                }

                // Set on options
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;
                options.customer_id     = this.communicationManager?.customer_id;
                options.campaign_customer_active_id = this.currentCampaignCustomer.campaign_customer_active_id;

                DestroyCustomerAddress(options.customer_address_id, options).then((response) => {
                    if (!(response?.Error?.length > 0)) {
                        const idxToRemove = this.communicationManager.customer_addresses.findIndex(address => address.customer_address_id === options.customer_address_id);
                        if (idxToRemove >= 0) {
                            this.communicationManager.customer_addresses.splice(idxToRemove, 1);
                        }
                        this.communicationManager.primary_address_id = response?.Data?.primary_address_id;
                        this.showSnackbar("green", "Successfully deleted customer address");
                    } else {
                        if (response?.Error == "Address is linked to a mortgage prospect record and cannot be destroyed!") {
                            this.showSnackbar("red", response.Error);
                        } else {
                            this.showSnackbar("red", "Failed to delete customer address");
                        }
                    }
                });
            },
            destroyCustomerEmailAddress(options) {

                // Verify valid
                if (!(options?.customer_email_address_id > 0)) {
                    return;
                }

                // Set on options
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;
                options.customer_id     = this.communicationManager?.customer_id;

                DestroyCustomerEmailAddress(options.customer_email_address_id, options).then((response) => {
                    if (!(response?.Error?.length > 0)) {
                        const idxToRemove = this.communicationManager.customer_email_addresses.findIndex(emailAddress => emailAddress.customer_email_address_id === options.customer_email_address_id);
                        if (idxToRemove >= 0) {
                            this.communicationManager.customer_email_addresses.splice(idxToRemove, 1);
                        }
                        this.communicationManager.primary_email_address_id = response?.Data?.primary_email_address_id;
                        this.showSnackbar("green", "Successfully deleted customer email address");
                    } else {
                        this.showSnackbar("red", "Failed to delete customer email address");
                    }
                });
            },
            destroyCustomerPhoneNumber(options) {

                // Verify valid
                if (!(options?.customer_phone_number_id > 0)) {
                    return;
                }

                // Set on options
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;
                options.customer_id     = this.communicationManager?.customer_id;

                DestroyCustomerPhoneNumber(options.customer_phone_number_id, options).then((response) => {
                    if (!(response?.Error?.length > 0)) {
                        const idxToRemove = this.communicationManager.customer_phone_numbers.findIndex(phoneNumber => phoneNumber.customer_phone_number_id === options.customer_phone_number_id);
                        if (idxToRemove >= 0) {
                            this.communicationManager.customer_phone_numbers.splice(idxToRemove, 1);
                        }
                        this.communicationManager.cell_phone_id = response?.Data?.cell_phone_id;
                        this.communicationManager.home_phone_id = response?.Data?.home_phone_id;
                        this.communicationManager.work_phone_id = response?.Data?.work_phone_id;
                        this.showSnackbar("green", "Successfully deleted customer phone number");
                    } else {
                        this.showSnackbar("red", "Failed to delete customer phone number");
                    }
                });
            },
            exitEmailCustomerEmailAddress() {
                this.emailCustomerEmailAddress = null;
            },
            exitMessageCustomerPhoneNumber() {
                this.messageCustomerPhoneNumber = null;
                this.messageIncomingPhoneNumber = null;
            },
            getCustomerCommunicationManager() {
                const options = {
                    customer_id: this.currentCampaignCustomer?.customer_id,
                }

                this.isLoading = true;
                GetCustomerCommunicationManager(options).then((response) => {
                    if (response?.Data?.communication_manager?.customer_id > 0 && response?.Data?.communication_manager?.customer_id === this.currentCampaignCustomer?.customer_id) {
                        this.communicationManager = response.Data.communication_manager;
                    }
                    this.isLoading = false;
                });
            },
            getXtimeLink() {

                // If there's already an xtime link then return
                // Verify campaign_customer_active_id and customer_id
                if (!(!this.xtimeLink && this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_active_id: this.currentCampaignCustomer.campaign_customer_active_id,
                    customer_id: this.currentCampaignCustomer.customer_id,
                }

                GetXtimeLink(options).then((response) => {
                    if (response?.Data?.xtime_link?.organization_plugin_id > 0) {
                        this.xtimeLink = response.Data.xtime_link;
                    }
                });
            },
            onEditCustomerAddress(response) {

                // Verify customer address
                const newCustomerAddress = response?.Data?.new_customer_address;
                const oldCustomerAddress = response?.Data?.old_customer_address;
                if (!(newCustomerAddress?.customer_address_id > 0 && oldCustomerAddress?.customer_address_id > 0 && oldCustomerAddress?.customer_id > 0)) {
                    return;
                }

                // Verify it belongs to campaign customer
                if (this.currentCampaignCustomer?.customer_id !== oldCustomerAddress.customer_id) {
                    return;
                }

                // Verify there are customer addresses to update
                if (!(this.communicationManager?.customer_addresses?.length > 0)) {
                    return;
                }

                const idxToRemove = this.communicationManager.customer_addresses.findIndex(customerAddress => customerAddress.customer_address_id === oldCustomerAddress?.customer_address_id);
                if (idxToRemove >= 0) {
                    this.communicationManager.customer_addresses.splice(idxToRemove, 1, newCustomerAddress)
                }

                this.communicationManager.primary_address_id = response?.Data?.primary_address_id;
            },
            onNewCustomerAddress(response) {

                // Verify customer address
                const customerAddress = response?.Data?.customer_address;
                if (!(customerAddress?.customer_address_id > 0 && customerAddress?.customer_id > 0)) {
                    return;
                }

                // Verify it belongs to campaign customer
                if (this.currentCampaignCustomer?.customer_id !== customerAddress.customer_id) {
                    return;
                }

                // Add customer address
                let customerAddressAlreadyExists = false
                const idxToRemove = this.communicationManager.customer_addresses.findIndex(x => x.customer_address_id === customerAddress?.customer_address_id);
                if (idxToRemove >= 0) {
                    this.communicationManager.customer_addresses.splice(idxToRemove, 1, customerAddress)
                    customerAddressAlreadyExists = true
                }

                if (!customerAddressAlreadyExists) {
                    this.communicationManager.customer_addresses.push(customerAddress);
                }

                this.communicationManager.primary_address_id = response?.Data?.primary_address_id;
            },
            openSwitchCampaignDialog() {
                this.switchCampaignDialog = true;
            },
            setEmailCustomerEmailAddress(customerEmailAddress) {

                // Verify
                if (!customerEmailAddress || !(customerEmailAddress.customer_email_address_id > 0)) {
                    return;
                }

                // Ensure able to email
                if (customerEmailAddress.do_not_email === true || customerEmailAddress.email_address_do_not_email === true || customerEmailAddress.invalid === true || customerEmailAddress.email_address_invalid === true) {
                    return;
                }

                // Set
                this.emailCustomerEmailAddress = { ...customerEmailAddress };
            },
            setMessageCustomerPhoneNumber(cpn, ipn) {

                // Verify
                if (!cpn || !(cpn.customer_phone_number_id > 0)) {
                    return;
                }

                // Ensure able to message
                if (cpn.do_not_message === true || cpn.phone_number_do_not_message === true) {
                    return;
                }

                // Set incoming phone number if applicable
                if (ipn?.incoming_phone_number_id > 0) {
                    this.messageIncomingPhoneNumber = { ...ipn };
                }

                // Set
                this.messageCustomerPhoneNumber = { ...cpn };
            },
            setSubscriptionChangesOnCommunicationManager(response) {

                // Verify response communication manager matches data cm
                if (!(this.communicationManager?.customer_id > 0 && response?.Data?.customer_communication_manager?.customer_id > 0 && response?.Data?.customer_communication_manager?.customer_id === this.communicationManager?.customer_id)) {
                    return;
                }
                    
                // Customer level subscribes
                this.communicationManager.subscribed_communication_type_all = response.Data.customer_communication_manager.subscribed_communication_type_all;
                this.communicationManager.subscribed_communication_type_email = response.Data.customer_communication_manager.subscribed_communication_type_email;
                this.communicationManager.subscribed_communication_type_mail = response.Data.customer_communication_manager.subscribed_communication_type_mail;
                this.communicationManager.subscribed_communication_type_message = response.Data.customer_communication_manager.subscribed_communication_type_message;
                this.communicationManager.subscribed_communication_type_phone = response.Data.customer_communication_manager.subscribed_communication_type_phone;
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
            updateCustomerAddress(options) {

                // Verify valid current campaign customer
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                // Set on options
                options.customer_id     = this.currentCampaignCustomer.customer_id;
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;

                UpdateCustomerAddress(options.customer_address_id, options).then((response) => {
                    if (response?.Data?.customer_address?.customer_address_id > 0) {
                        const idxToRemove = this.communicationManager.customer_addresses.findIndex(customerAddress => customerAddress.customer_address_id === options.customer_address_id);
                        if (idxToRemove >= 0) {
                            this.communicationManager.customer_addresses.splice(idxToRemove, 1, response.Data.customer_address)
                        }

                        this.communicationManager.primary_address_id = response?.Data?.primary_address_id;
                        this.showSnackbar("green", "Successfully updated address");
                    } else {
                        this.showSnackbar("red", "Failed to update address");
                    }
                });
            },
            updateCustomerAddressPrimary(customerAddressId) {

                // Verify valid
                if (!(this.communicationManager?.customer_id > 0)) {
                    return;
                }
                if (!(customerAddressId > 0)) {
                    return;
                }

                // Set on options
                const options = {
                    account_id: this.communicationManager?.account_id,
                    organization_id: this.communicationManager?.organization_id,
                    customer_id: this.communicationManager?.customer_id,
                    customer_address_id: customerAddressId,
                }

                UpdateCustomerAddressPrimary(options.customer_id, options).then((response) => {
                    if (response?.Data?.primary_address_id > 0) {
                        this.communicationManager.primary_address_id = response.Data.primary_address_id;
                        this.showSnackbar("green", "Successfully updated primary address");
                    } else {
                        this.showSnackbar("red", "Failed to update primary address");
                    }
                });
            },
            updateCustomerEmailAddress(options) {

                // Verify valid current campaign customer
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                // Set on options
                options.customer_id     = this.currentCampaignCustomer.customer_id;
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;

                UpdateCustomerEmailAddress(options.customer_email_address_id, options).then((response) => {
                    if (response?.Data?.customer_email_address?.customer_email_address_id > 0) {
                        const idxToRemove = this.communicationManager.customer_email_addresses.findIndex(customerEmailAddress => customerEmailAddress.customer_email_address_id === options.customer_email_address_id);
                        if (idxToRemove >= 0) {
                            this.communicationManager.customer_email_addresses.splice(idxToRemove, 1, response.Data.customer_email_address)
                        }

                        this.communicationManager.primary_email_address_id = response?.Data?.primary_email_address_id;
                        this.showSnackbar("green", "Successfully updated email address");
                    } else {
                        this.showSnackbar("red", "Failed to update email address");
                    }
                });
            },
            updateCustomerEmailAddressPrimary(customerEmailAddressId) {

                // Verify valid
                if (!(this.communicationManager?.customer_id > 0)) {
                    return;
                }
                if (!(customerEmailAddressId > 0)) {
                    return;
                }

                // Set on options
                const options = {
                    account_id: this.communicationManager?.account_id,
                    organization_id: this.communicationManager?.organization_id,
                    customer_id: this.communicationManager?.customer_id,
                    customer_email_address_id: customerEmailAddressId,
                }

                UpdateCustomerEmailAddressPrimary(options.customer_id, options).then((response) => {
                    if (response?.Data?.primary_email_address_id > 0) {
                        this.communicationManager.primary_email_address_id = response.Data.primary_email_address_id;
                        this.showSnackbar("green", "Successfully updated primary email address");
                    } else {
                        this.showSnackbar("red", "Failed to update primary email address");
                    }
                });
            },
            updateCustomerPhoneNumber(options) {

                // Verify valid current campaign customer
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                // Set on options
                options.customer_id     = this.currentCampaignCustomer.customer_id;
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;

                UpdateCustomerPhoneNumber(options.customer_phone_number_id, options).then((response) => {
                    if (response?.Data?.customer_phone_number?.customer_phone_number_id > 0) {
                        const idxToRemove = this.communicationManager.customer_phone_numbers.findIndex(customerPhoneNumber => customerPhoneNumber.customer_phone_number_id === options.customer_phone_number_id);
                        if (idxToRemove >= 0) {
                            this.communicationManager.customer_phone_numbers.splice(idxToRemove, 1, response.Data.customer_phone_number)
                        }

                        this.communicationManager.cell_phone_id = response?.Data?.cell_phone_id;
                        this.communicationManager.home_phone_id = response?.Data?.home_phone_id;
                        this.communicationManager.work_phone_id = response?.Data?.work_phone_id;
                        this.showSnackbar("green", "Successfully updated phone number");
                    } else {
                        this.showSnackbar("red", "Failed to update phone number");
                    }
                });
            },
            updateCustomerPhonePrimary(customerPhoneNumberId) {

                // Verify valid
                if (!(this.communicationManager?.customer_id > 0)) {
                    return;
                }
                if (!(customerPhoneNumberId > 0)) {
                    return;
                }

                // Set on options
                const options = {
                    account_id: this.communicationManager?.account_id,
                    organization_id: this.communicationManager?.organization_id,
                    customer_id: this.communicationManager?.customer_id,
                    customer_phone_number_id: customerPhoneNumberId,
                }

                UpdateCustomerPhonePrimary(options.customer_id, options).then((response) => {
                    if (response?.Data?.cell_phone_id > 0 || response?.Data?.home_phone_id > 0 || response?.Data?.work_phone_id > 0) {
                        this.communicationManager.cell_phone_id = response.Data.cell_phone_id;
                        this.communicationManager.home_phone_id = response.Data.home_phone_id;
                        this.communicationManager.work_phone_id = response.Data.work_phone_id;
                        this.showSnackbar("green", "Successfully updated primary phone number");
                    } else {
                        this.showSnackbar("red", "Failed to update primary phone number");
                    }
                });
            },
            updateCustomerCommunicationManager(options) {

                // Verify valid
                if (!(this.communicationManager?.customer_id > 0)) {
                    return;
                }

                // Set on options
                options.account_id      = this.communicationManager?.account_id;
                options.organization_id = this.communicationManager?.organization_id;
                options.customer_id     = this.communicationManager?.customer_id;

                UpdateCustomerCommunicationManager(options.customer_id, options).then((response) => {
                    if (response?.Data?.communication_manager?.customer_id > 0 && response?.Data?.communication_manager?.customer_id === this.currentCampaignCustomer?.customer_id) {
                        this.communicationManager = response.Data.communication_manager;
                        this.showSnackbar("green", "Successfully updated customer");
                    } else {
                        this.showSnackbar("red", "Failed to update customer");
                    }
                });
            },
        },
        computed: {
            accountIsPhoneAttemptVisible() {
                return this.currentUser?.account?.is_phone_attempt_visible === true;
            },
            attemptDetails() {
                const details = [];

                // Outbound lead
                if (this.call?.is_outbound_lead === true) {
                    details.push("LEAD");
                }

                // Attempt number
                if (this.accountIsPhoneAttemptVisible && this.nextAttemptNumber?.length > 0) {
                    details.push(this.nextAttemptNumber);
                }

                // Direction
                if (this.callDirection?.length > 0) {
                    details.push(capitalizeString(this.callDirection));
                }

                // Caller ID
                if (this.callCustomerCallerID?.length > 0) {
                    details.push(formatPhoneNumber(this.callCustomerCallerID));
                }

                return details.join(", ");
            },
            callCustomerCallerID() {

                // Init
                let callerID = "";
                if (!(this.call?.direction?.length > 0)) {
                    return callerID;
                }

                // Switch over direction
                switch(this.callDirection) {
                case "inbound":
                    callerID = this.call?.friendly_from_number;
                    break;

                case "outbound":
                    callerID = this.call?.friendly_to_number;
                    break;

                }

                return callerID;
            },
            callDirection() {
                return this.call?.direction?.length > 0 ? this.call.direction : "";
            },
            campaignID() {
                return this.currentCampaignCustomer?.campaign_id > 0 ? this.currentCampaignCustomer.campaign_id : 0;
            },
            campaignName() {
                return this.currentCampaignCustomer?.campaign_id > 0 && this.currentCampaignCustomer?.campaign_name?.length > 0 ? this.currentCampaignCustomer?.campaign_name : null;
            },
            currentCampaignCustomerID() {
                return (this.currentCampaignCustomer?.campaign_customer_id || 0);
            },
            currentUser() {
                return VolieState.currentUser();
            },
            customerName() {
                return formatName([this.communicationManager?.first_name, this.communicationManager?.middle_name, this.communicationManager?.last_name]);
            },
            outputMessageIncomingPhoneNumberID() {
                return this.messageIncomingPhoneNumber?.incoming_phone_number_id > 0 ? this.messageIncomingPhoneNumber?.incoming_phone_number_id : this.currentCampaignCustomer?.incoming_phone_number?.incoming_phone_number_id;
            },
            nextAttemptNumber() {
                return this.currentCampaignCustomer?.next_attempt_number > 0 ? numberToOrdinal(this.currentCampaignCustomer.next_attempt_number) + " Attempt" : "";
            },
            renderSwitchCampaignsButton() {
                // todo - add support to switch campaigns for missed call
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.expiration_code === this.ccExpirationCodeNeverEligible && this.call?.id > 0 && this.call?.direction === 'inbound' && this.call?.is_campaign_guess === true;
            },
            renderXtimeLink() {
                return this.xtimeLink?.dealer_key?.length > 0 && this.xtimeLink?.dc_param?.length > 0 && this.xtimeLink?.di_param?.length > 0 && this.xtimeLink?.base_url?.length > 0 && this.xtimeLink?.phone_number?.length > 0;
            },
            validCommunicationManager() {
                return this.communicationManager?.customer_id > 0;
            },
            validEmailCustomerEmailAddress() {
                return this.emailCustomerEmailAddress?.customer_email_address_id > 0;
            },
            validMessageCustomerPhoneNumber() {
                return this.messageCustomerPhoneNumber?.customer_phone_number_id > 0;
            },
        },
        components: {
            CommunicationPreferences,
            CopyableText,
            CustomerAddresses,
            CustomerEmailAddresses,
            CustomerGeneralInfoFormDialog,
            CustomerPhoneNumbers,
            EmailClient,
            MessageSoftphone,
            SwitchCampaign,
            XtimeLinkForm,
        },
        props: {
            audioEmailIncoming: HTMLAudioElement,
            audioEmailOutcoming: HTMLAudioElement,
            call: Object,
            currentCampaignCustomer: Object,
            forceSubscriptionChangesResponse: Object,
            handleUpdateCurrentCampaignCustomer: Function,
            hideCreateUpdateDestroyButtons: Boolean,
            inCommunicationCenter: Boolean,
            isRacOnline: Boolean,
            parentHeight: Number,
            reassignCampaignCustomerCampaign: Function,
            setCommunicationManager: Function,
        },
        watch: {
            communicationManager: {
                deep: true,
                handler() {
                    this.setCommunicationManager(this.communicationManager);
                    this.handleUpdateCurrentCampaignCustomer(this.communicationManager);
                },
            },
            currentCampaignCustomer: {
                deep: true,
                handler() {
                    this.getXtimeLink();
                },
            },
            forceSubscriptionChangesResponse: {
                deep: true,
                handler() {
                    if (!!this.forceSubscriptionChangesResponse) {
                        this.setSubscriptionChangesOnCommunicationManager(this.forceSubscriptionChangesResponse);
                    }
                },
            },
        },
        created() {
            this.getCustomerCommunicationManager();
            this.getXtimeLink();
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "newCustomerAddress" }}, this.onNewCustomerAddress);
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "editCustomerAddress" }}, this.onEditCustomerAddress);
		},
        destroyed() {
            this.setCommunicationManager(null);
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "newCustomerAddress" }}, this.onNewCustomerAddress);
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "editCustomerAddress" }}, this.onEditCustomerAddress);
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
        .list-item-title-overflow {
            overflow: auto !important;
            white-space: normal !important;
        }
    }
</style>
