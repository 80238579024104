import { render, staticRenderFns } from "./card.vue?vue&type=template&id=a929af94&scoped=true&lang=pug"
import script from "./card.vue?vue&type=script&lang=ts"
export * from "./card.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a929af94",
  null
  
)

export default component.exports