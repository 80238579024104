<template lang="pug">
    .ma-sm-2
        v-spacer
        v-container.mx-auto.text-center(v-if="isLoading")
            .text-center Loading auto-assistant data and transcript...
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-snackbar(
            v-model='snackbarOpen'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}

            template(v-slot:action="{ attrs }")
                v-btn(dark text @click='snackbarOpen = false' v-bind="attrs")
                    | Close

        v-container.mx-auto(v-if="!isLoading && validAutoAssistData")
            StellaAiData(
                v-if="assistantIsStella"
                :auto-assist-data="autoAssistData"
                :valid-auto-assist-data="validAutoAssistData"
            )
        v-alert(v-else-if="!isLoading && displayFailureMessage" outlined type='info' dense border='left' style="margin-top: 0px;")
            | Unable to retrieve AI Assistant call data.

</template>

<script lang="ts">
    import Vue from 'vue';

    import VolieState  from "@/store";

    // Components
    import StellaAiData from '@/components/ai_assistants/stella_ai_data.vue';

    // Data

    export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                firstRequestSent: false,
                isLoading: true,
                displayFailureMessage: false,
                snackbarOpen: false,
                snackbar: {
                    timeout: 20000,
                },
            }
        },
        methods: {
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbarOpen = true;
                }
            },
        },
        computed: {
            assistantIsStella() {
                return this.autoAssistData?.auto_assistant_type === 'stella';
            },
            fetchFailed() {
                return this.autoAssistData?.auto_assistant_type === 'error'
            },
            validAutoAssistData() {
                return this.autoAssistData?.auto_assistant_type != "" && this.autoAssistData?.event_body?.length > 0
            },
            validCampaignCustomer() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.campaign_customer_id > 0;
            },
        },
        components: {
            StellaAiData,
        },
        props: {
            autoAssistData: Object,
            callId: Number,
            currentCampaignCustomer: Object,
            inCommunicationCenter: Boolean,
        },
        watch: {
            "autoAssistData"() {
                if (this.validAutoAssistData) {
                    this.showSnackbar("green", "This call has been transferred from an AI assistant, you will find details of the call below.")
                } else if (this.fetchFailed) {
                    this.showSnackbar("red", "Unable to retrieve AI Assistant call data.")
                    this.displayFailureMessage = true;
                }
                this.isLoading = false;
            },
        },
        created() {
            if (this.validAutoAssistData) {
                this.isLoading = false;
                this.showSnackbar("green", "This call has been transferred from an AI assistant, you will find details of the call below.")
            } else if (this.fetchFailed) {
                this.showSnackbar("red", "Unable to retrieve AI Assistant call data.")
                this.isLoading = false;
                this.displayFailureMessage = true;
            } else {
                this.showSnackbar("green", "This call has been transferred from an AI assistant, call details are currently loading.")
            }
        },
    });
</script>

<style lang="scss" scoped>
</style>
