<template lang="pug">
    ExpandableCardListItem.mt-0.mb-4(
        v-if="validService"
        id="service-details-card"
        :color="itemColor"
        :content-right-top="service.friendly_close_date"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :title="vehicleYearMakeModel"
        :subtitle="'RO# ' + service.ro_number"
    )
        template(v-slot:body)
            template(v-if="showDetails === true")
                v-card-text.white.text--primary
                    v-list.py-0(
                        two-line
                    )
                        ListItem(
                            default-title="N/a"
                            icon="mdi-car"
                            indent
                            subtitle="Vehicle"
                            :title="vehicleYearMakeModel"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="VIN"
                            :title="service.vehicle_vin"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-account"
                            indent
                            subtitle="Advisor Name"
                            :title="service.advisor_name"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-calendar"
                            indent
                            subtitle="Open Date"
                            :title="service.friendly_open_date"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Close Date"
                            :title="service.friendly_close_date"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Created At"
                            :title="service.friendly_created_at_date"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-counter"
                            number-filter
                            indent
                            subtitle="Odometer In"
                            :title="service.odometer_in"
                        )
                        ListItem(
                            default-title="N/a"
                            number-filter
                            indent
                            subtitle="Odometer Out"
                            :title="service.odometer_out"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-car-wrench"
                            dollar-filter
                            indent
                            subtitle="Labor Amount Customer Pay"
                            :title="service.labor_amount_customer_pay"
                        )
                        ListItem(
                            default-title="N/a"
                            dollar-filter
                            indent
                            subtitle="Labor Amount Internal"
                            :title="service.labor_amount_internal"
                        )
                        ListItem(
                            default-title="N/a"
                            dollar-filter
                            indent
                            subtitle="Labor Amount Warranty"
                            :title="service.labor_amount_warranty"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-tools"
                            dollar-filter
                            indent
                            subtitle="Parts Amount Customer Pay"
                            :title="service.parts_amount_customer_pay"
                        )
                        ListItem(
                            default-title="N/a"
                            dollar-filter
                            indent
                            subtitle="Parts Amount Internal"
                            :title="service.parts_amount_internal"
                        )
                        ListItem(
                            default-title="N/a"
                            dollar-filter
                            indent
                            subtitle="Parts Amount Warranty"
                            :title="service.parts_amount_warranty"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-invoice-list"
                            dollar-filter
                            indent
                            subtitle="Repair Order Amount"
                            :title="service.ro_amount"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Repair Order Number"
                            :title="service.ro_number"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Repair Order Status"
                            :title="service.ro_status"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Repair Order Status Description"
                            :title="service.ro_status_description"
                        )

                        ServiceLineDetails(
                            v-if="validServiceLines"
                            v-for='serviceLine in service.service_lines'
                            :key='serviceLine.service_line_id'
                            :service-line="serviceLine"
                        )
                        template(v-else) 
                            ListItem(
                                title="None"
                                indent
                                subtitle="Service Line Items"
                            )

                        .text-center(v-if="!showMore")
                            v-btn(text plain color="primary" @click="showMore = true") 
                                | Show More

                        template(v-else)
                            v-divider(inset)
                            ListItem(
                                icon="mdi-file-document-plus"
                                indent
                                subtitle="Service Vid"
                                :title="service.service_key"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Promise Date"
                                :title="service.friendly_promise_date"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Customer Number"
                                :title="service.customer_number"
                            )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';
    import ServiceLineDetails from '@/components/services/service_line_details.vue';

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
				showDetails: false,
                showMore: false,
            }
        },
        methods: {
        },
        computed: {
            itemColor() {
                return resourceColorClass("Service");
            },
            validService() {
                return this.service?.service_id > 0;
            },
            validServiceLines() {
                return this.service?.service_lines?.length > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validService) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.service.vehicle_model_year,
                    vehicleMake:      this.service.vehicle_make,
                    vehicleModel:     this.service.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        components: {
            ExpandableCardListItem,
            ListItem,
            ServiceLineDetails,
        },
        props: {
            service: Object,
        },
        watch: {
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
