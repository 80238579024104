<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(icon light v-on='on')
                v-icon mdi-trash-can-outline
        v-card
            v-card-title
                span.text-h5 Remove Custom field
            v-card-text
                v-container
                    span.text-h6 Are you sure you want to delete this custom field?

                v-container
                    v-simple-table
                        template(v-slot:default)
                            thead
                                tr
                                    th.text-capitalize.text-overline.text--secondary.text--darken-3(style="width: 40%;") Key
                                    th.text-capitalize.text-overline.text--secondary.text--darken-3(style="width: 60%;") Value
                            tbody
                                tr(v-if="validCustomField" :key='customField.custom_field_id')
                                    td.text-left {{ customField.key }}
                                    td.text-left {{ customField.value }}

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false;')
                        v-icon mdi-window-close
                        | Close
                    v-btn.ma-2(color="red" outlined ripple :disabled="!validCustomField" @click='handleDestroyCustomField(); dialog = false;')
                        v-icon mdi-content-save
                        | Delete

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            handleDestroyCustomField() {
                // Return if not valid options
                if (!this.validCustomField) {
                    return;
                }

                const options = {
                    custom_field_id: this.customField.custom_field_id,
                }

                this.destroyCustomField(options);
            },
        },
        computed: {
            validCustomField() {
                return this.customField?.custom_field_id > 0
            },
        },
        components: {},
        props: {
            customField:        Object,
            destroyCustomField: Function,
        },
        created() {
            return;
        },
    });
</script>

<style lang="scss" scoped>
</style>