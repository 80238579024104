<template lang="pug">
    div.pa-md-5.width-100-percent
        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        v-dialog(v-model='commentDialog' persistent='' max-width='600px')
            v-card
                v-card-title
                    span.text-h5 Update Comment
                v-card-text
                    v-container
                        v-row
                            v-col(cols='12')
                                tiptap-vuetify(
                                    v-model='scopeActivity.comments_message'
                                    :extensions='extensions'
                                    :toolbar-attributes="{ color: '#0f5b9a', dark: true }"
                                )

                v-card-actions
                    v-spacer
                        v-btn.ma-2(color="#0f5b9a" outlined ripple @click='commentDialog = false; scopeOrgActivity = null')
                            v-icon mdi-window-close
                            | Close
                        v-btn.ma-2(color="success" outlined ripple @click='commentDialog = false; updateTicketComment();')
                            v-icon mdi-content-save
                            | Save

        div.pa-md-5.width-100-percent
            v-card.mx-lg-auto(outlined width="100%")
                v-toolbar.volie-v-toolbar-color(dark)
                    v-toolbar-title Ticket

                v-row.no-margin(v-if="validTicket && !isLoading")
                    v-col(cols='12')
                        TicketCard(
                            :ticket="ticket"
                            :show-snackbar="showSnackbar"
                        )

                    template(v-if="isSourceAdmin")
                        v-col(cols='6' style="padding-right: 0px;")
                            v-card.mx-lg-auto(outlined width="100%")
                                v-toolbar.volie-v-toolbar-color(dark)
                                    v-toolbar-title Edit Ticket

                                Form(
                                    :reload-ticket="findTicket"
                                    :show-snackbar="showSnackbar"
                                    :ticket="ticket"
                                )

                            v-row
                                v-col(cols='12')
                                    .text-left
                                        v-btn.ma-2(color="#0f5b9a" outlined ripple :to="{ name: 'ticket_index' }")
                                            v-icon mdi-arrow-left
                                            | Back

                        v-col(cols='6')
                            v-card.mx-lg-auto(outlined width="100%")

                                v-toolbar.volie-v-toolbar-color(dark)
                                    v-toolbar-title Activity

                                Activity(
                                    v-if="activities.length > 0"
                                    :activities="activities"
                                    :source="source"
                                    :update-comment="onUpdateComment"
                                )

                        v-col(cols='12' v-if="currentUserIsSuperAdmin" )
                            Tasks(
                                :ticket="ticket"
                            )

                        v-col(cols='12' v-if="currentUserIsSuperAdmin" )
                            TicketFollowers(
                                :ticket="ticket"
                            )

                    template(v-else-if="isSourceSharedLink")
                        v-col(cols='12')
                            v-card.mx-lg-auto(outlined width="100%")

                                v-toolbar.volie-v-toolbar-color(dark)
                                    v-toolbar-title Activity

                                Activity(
                                    v-if="activities.length > 0"
                                    :activities="activities"
                                    :source="source"
                                    :update-comment="onUpdateComment"
                                )

                .text-center(v-if="isLoading")
                    v-progress-circular(
                        :size='100'
                        :width='10'
                        color='#0f5b9a'
                        indeterminate
                        style="margin-top: 100px; margin-bottom: 100px;"
                    )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Data
    import {
        FindTicket,
        UpdateTicketComment,
    } from "@/data";

    // Components
    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Strike,
        Underline,
        // Code,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        // HorizontalRule,
        // History,
    } from 'tiptap-vuetify';

    import Activity from '@/components/tickets/partials/activity.vue';
    import Form from '@/components/tickets/form.vue';
    import Tasks from '@/components/internal_ticket_tasks/index.vue';
    import TicketCard from '@/components/tickets/card.vue';
    import TicketFollowers from '@/components/ticket_followers/index.vue';

    export default Vue.extend({
        data() {
            return {
                activities: [],
                assignables: [],
                commentDialog: false,
                extensions: [
                    Blockquote,
                    Link,
                    Underline,
                    Strike,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList,
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Bold,
                    Paragraph,
                    HardBreak
                ],
                isLoading: false,
                scopeActivity: {},
                scopeOrgActivity: null,
                scopeComment: {},
                skipActivatedAccountScope: false,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
                ticket: null,
            }
        },
        methods: {
            findTicket() {
                if (!this.validTicketKey) {
                    return;
                }

                const options = {
                    skipActivatedAccountScope: this.skipActivatedAccountScope,
                };

                // If super admin, skip activated account scope
                if (this.currentUserIsSuperAdmin) {
                    options.skipActivatedAccountScope = true;
                }

                this.isLoading = true;
                FindTicket(this.ticketKey, options).then((response) => {

                    if (response?.Data?.ticket?.ticket_id > 0) {
                        this.ticket = response.Data.ticket;
                    }
                    if (response?.Data?.activities?.length > 0) {
                        this.activities = response.Data.activities;
                    }

                    this.activities.forEach(activity => {
                        if (activity.branch_name && !this.ticket.branch_name) {
                            this.ticket.branch_name = activity.branch_name;
                        }
                        if (activity.commit_number && !this.ticket.commit_number) {
                            this.ticket.commit_number = activity.commit_number;
                        }
                    });

                    this.isLoading = false;
                });
            },
            onUpdateComment(activity) {
                this.scopeActivity = { ...activity }
                this.scopeOrgActivity = activity
                this.commentDialog = true;
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
            updateTicketComment() {
                const options = {
                    ticket_activity_id: this.scopeActivity.ticket_activity_id,
                    current_user_id: this.currentUser.user_id,
                    comments: this.scopeActivity.comments_message,
                }

                UpdateTicketComment(options).then((response) => {
                    if (response?.Error === null) {
                        if (this.scopeOrgActivity) {
                            this.scopeOrgActivity.comments_message = this.scopeActivity.comments_message
                        }
                        this.showSnackbar("success", "Successfully updated ticket comment");
                    } else {
                        let err = response?.Error;
                        if (!(err?.length > 0)) {
                            err = "Unable to update ticket comment"
                        }
                        this.showSnackbar("error", err);
                    }
                    this.scopeOrgActivity = null
                });
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            currentUserIsSuperAdmin() {
                return this.currentUser?.activated_role_id === 1;
            },
            eligibleToSave() {
                if (!(this.obj?.title?.length > 0)) {
                    return false;
                }
                if (!(this.obj?.ticket_type?.length > 0)) {
                    return false;
                }
                if (!(this.obj?.priority?.length > 0)) {
                    return false;
                }
                if (!(this.obj?.current_status?.length > 0)) {
                    return false;
                }

                if (this.obj?.current_status == "done" && !(this.obj?.completion_code?.length > 0)) {
                    return false;
                }

                return true;
            },
            getAccountId() {
                return VolieState.currentUser()?.account?.account_id;
            },
            getTicketId() {
                return this.ticket?.id;
            },
            isSourceAdmin() {
                return this.source === "admin";
            },
            isSourceSharedLink() {
                return this.source === "shared_link";
            },
            validTicket() {
                return this.ticket?.ticket_id > 0;
            },
            validTicketKey() {
                return this.ticketKey?.length > 0;
            },
        },
        components: {
            Activity,
            Form,
            Tasks,
            TicketCard,
            TicketFollowers,
            TiptapVuetify,
        },
        props: {
            source: String,
            ticketKey: String,
        },
        created() {
            this.findTicket();
        },
    });
</script>

<style lang="scss" scoped>
    .no-margin {
        margin: 0;
    }
    .activity-cell {
        text-align: right !important;
        padding: 6px 0;
        font-size: 12px;
    }
    .dashed-border {
        border-bottom: 1px dashed #cfdadd;
    }
</style>
