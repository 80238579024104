<template lang="pug">
    v-menu(v-model="menu" :close-on-content-click="false")
        template(v-slot:activator='{ on: menu, attrs }')
            v-tooltip(top)
                template(v-slot:activator='{ on: tooltip, attrs }')
                    span(
                        v-bind="attrs" 
                        v-on="{ ...tooltip, ...menu }"
                        role="text"
                        style="cursor: pointer;"
                        @mouseleave="onMouseLeave()"
                    )
                        | {{ formattedPhoneNumber }}

                .toolbar-container-column
                    .toolbar-row
                        strong.text-center 
                            | {{ message }}
                    .toolbar-row
                        span 
                            | {{ formattedPhoneNumber }}
        v-list 
            .toolbar-container-column
                .toolbar-row(style="margin-bottom: 10px; display: flex; flex-direction: row; justify-content: flex-start; align-items: center;")
                    v-icon(small style="padding-left: 10px; margin-right: 10px;") mdi-content-copy
                    strong.text-center 
                        | {{ message }}

            v-list-item-group(:key="menuKey")
                v-list-item(v-if='formattedPhoneNumber' :key='1')
                    v-list-item-title
                        span(
                            v-clipboard:copy="formattedPhoneNumber"
                            v-clipboard:success="onCopySuccess"
                            v-clipboard:error="onCopyError"
                            @click="onClickMenuItem()"
                        )
                            | {{ formattedPhoneNumber }}
                v-list-item(v-if='phoneNumberPhoneNumber' :key='2')
                    v-list-item-title
                        span(
                            v-clipboard:copy="phoneNumberPhoneNumber"
                            v-clipboard:success="onCopySuccess"
                            v-clipboard:error="onCopyError"
                            @click="onClickMenuItem()"
                        )
                            | {{ phoneNumberPhoneNumber }}
                v-list-item(v-if='phoneNumberWithoutCountryCode' :key='3')
                    v-list-item-title
                        span(
                            v-clipboard:copy="phoneNumberWithoutCountryCode"
                            v-clipboard:success="onCopySuccess"
                            v-clipboard:error="onCopyError"
                            @click="onClickMenuItem()"
                        )
                            | {{ phoneNumberWithoutCountryCode }}

</template>

<script lang="ts">
    import Vue from 'vue';
    import VueClipboard from 'vue-clipboard2';
    VueClipboard.config.autoSetContainer = true;
    Vue.use(VueClipboard);

    // Helpers
    import { formatTel } from "@/helpers/general";

   export default Vue.extend({
       data() {
            return {
                menu: false,
                menuKey: null,
                message: 'Click to Copy',
            }
       },
       methods: {
            onClickMenuItem() {
                setTimeout(() => { 
                    this.menu = false 
                }, 500);
            },
            onCopyError() {
                this.message = 'Error copying!';
            },
            onCopySuccess() {
                this.message = 'Text Copied!';
            },
            onMouseLeave() {
                setTimeout(() => {
                    this.message = 'Click to Copy';
                }, 250);

                this.menuKey = Date.now();
            }
       },
       computed: {
            formattedPhoneNumber() {
                if (this.phoneNumber?.phone_number_phone_number?.length > 0) {
                    return formatTel(this.phoneNumber.phone_number_phone_number);
                }
                return null;
            },
            phoneNumberPhoneNumber() {
                if (this.phoneNumber?.phone_number_phone_number?.length > 0) {
                    return this.phoneNumber.phone_number_phone_number;
                }
                return null;
            },
            phoneNumberWithoutCountryCode() {
                if (this.phoneNumber?.phone_number_phone_number?.length > 0 && this.phoneNumber?.phone_number_country_code?.length > 0) {
                    return this.phoneNumber.phone_number_phone_number.replace(this.phoneNumber.phone_number_country_code, '');
                }
                return null;
            },
       },
       props: {
           phoneNumber: Object,
       },
    });
</script>

<style lang="scss" scoped>

    #app {
        .toolbar-container-column {
            display: flex; 
            flex-direction: column; 
            justify-content: center;
        }

        .toolbar-row {
            display: flex; 
            flex-direction: row; 
            justify-content: center;
        }
    }
</style>
