import { get, post } from '.';

export function GetTekionAppointments(options = {}) {
	return get<any>("/app/api/tekion/appointments", options);
}

export function ImportTekionAppointments(options = {}) {
	return post<any>("/app/api/tekion/appointments/import", options);
}

export function GetTekionAppointment(key) {
	return get<any>("/app/api/tekion/appointments/" + key);
}
