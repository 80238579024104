<template lang="pug">
    div(v-if="validTicket")
        v-card.mx-lg-auto(style="margin-bottom: 20px;")
            v-card-text(style="padding-bottom: 10px; font-size: 14px; font-weight: 500;")
                | {{ ticket.title }}

            v-container(v-if="!editable" class="tiptap-vuetify-editor__content" v-html="ticket.description" style="padding: 0px 25px;")

            div.mx-lg-auto(style="padding: 0px 10px 10px;")
                v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined :key='ticket.ticket_key')
                    v-toolbar(flat :src="toolbarBackground" height="100px")

                        v-toolbar-title(style="font-size: 13px; width: 12%; min-width: 12%;")
                            | {{ ticket.ticket_key }}
                            br
                            span.text-body-2
                                strong.info--text.text-uppercase(style="font-size: 13px;") {{ ticket.organization_id > 0 ? "Organization" : "Account" }}

                        v-spacer(style="width: 10px; min-width: 10px;")

                        v-toolbar-title(style="flex-grow: 50; font-size: 13px;")
                            TooltipText(text="Created At" :is-bold="true" :is-uppercase="true")
                            v-list-item-subtitle(style="font-size: 13px; margin-bottom: 7.5px; color: rgba(0, 0, 0, 0.6);")
                                CopyableText(v-if="ticket.friendly_created_at" :text="ticket.friendly_created_at.toString()")
                                template(v-else) ---
                            
                            TooltipText(text="Updated At" :is-bold="true" :is-uppercase="true")
                            v-list-item-subtitle(style="font-size: 13px; color: rgba(0, 0, 0, 0.6);")
                                CopyableText(v-if="ticket.friendly_updated_at" :text="ticket.friendly_updated_at.toString()")
                                template(v-else) ---

                        v-spacer(style="width: 10px; min-width: 10px;")

                        v-toolbar-title(style="flex-grow: 50; font-size: 13px;")
                            TooltipText(text="Assignable Type" :is-bold="true" :is-uppercase="true")
                            v-list-item-subtitle(style="font-size: 13px; margin-bottom: 7.5px; color: rgba(0, 0, 0, 0.6);")
                                CopyableText(v-if="ticket.assignable_type" :text="ticket.assignable_type.toUpperCase()")
                                template(v-else) ---
                            
                            TooltipText(text="Priority" :is-bold="true" :is-uppercase="true")
                            v-list-item-subtitle(style="font-size: 13px; color: rgba(0, 0, 0, 0.6);")
                                template(v-if="ticket.priority")
                                    strong.text-uppercase.info--text(v-if="ticket.priority === 'low'")
                                        | {{ ticket.priority }}
                                    strong.text-uppercase.warning--text(v-else-if="ticket.priority === 'high'")
                                        | {{ ticket.priority }}
                                    strong.text-uppercase.error--text(v-else-if="ticket.priority === 'critical'")
                                        | {{ ticket.priority }}
                                    strong.text-uppercase(v-else)
                                        | {{ ticket.priority }}
                                template(v-else) ---

                        v-spacer(style="width: 10px; min-width: 10px;")

                        v-toolbar-title(style="flex-grow: 50; font-size: 13px; min-width: 15%;")
                            TooltipText(text="Type" :is-bold="true" :is-uppercase="true")
                            v-list-item-subtitle(style="font-size: 13px; margin-bottom: 7.5px; color: rgba(0, 0, 0, 0.6);")
                                template(v-if="ticket.ticket_type")
                                    strong.text-uppercase.error--text(v-if="ticket.ticket_type === 'bug'")
                                        | {{ ticket.ticket_type | titleize }}
                                    strong.text-uppercase.success--text(v-else-if="ticket.ticket_type === 'enhancement'")
                                        | {{ ticket.ticket_type | titleize }}
                                    strong.text-uppercase.info--text(v-else-if="ticket.ticket_type === 'question'")
                                        | {{ ticket.ticket_type | titleize }}
                                    strong.text-uppercase(v-else)
                                        | {{ ticket.ticket_type | titleize }}
                                template(v-else) ---
                            
                            TooltipText(text="Status" :is-bold="true" :is-uppercase="true")
                            v-list-item-subtitle(style="font-size: 13px; color: rgba(0, 0, 0, 0.6);")
                                template(v-if="ticket.current_status")
                                    strong.text-uppercase.success--text(v-if="ticket.current_status === 'done'")
                                        | {{ ticket.current_status | titleize }}
                                    strong.text-uppercase.info--text(v-else-if="ticket.current_status === 'in_progress'")
                                        | {{ ticket.current_status | titleize }}
                                    strong.text-uppercase.info--text(v-else-if="ticket.current_status === 'in_review'")
                                        | {{ ticket.current_status | titleize }}
                                    strong.text-uppercase.warning--text(v-else-if="ticket.current_status === 'open'")
                                        | {{ ticket.current_status | titleize }}
                                    strong.text-uppercase(v-else)
                                        | {{ ticket.current_status | titleize }}
                                template(v-else) ---

                        v-spacer(style="width: 10px; min-width: 10px;")

                        v-toolbar-title(style="flex-grow: 50; font-size: 13px; min-width: 25%;")
                            TooltipText(text="Reporter" :is-bold="true" :is-uppercase="true")
                            v-list-item-subtitle(style="font-size: 13px; margin-bottom: 7.5px; color: rgba(0, 0, 0, 0.6);")
                                CopyableText(v-if="ticket.first_name || ticket.last_name" :text="( ticket.first_name.toString() + ' ' + ticket.last_name.toString() ).trim()")
                                template(v-else) ---
                            
                            TooltipText(text="Assignee" :is-bold="true" :is-uppercase="true")
                            v-list-item-subtitle(style="font-size: 13px; color: rgba(0, 0, 0, 0.6);")
                                CopyableText(v-if="ticket.supporter" :text="ticket.supporter.toString()")
                                template(v-else) ---

                        template(v-if="editable")
                            v-spacer(style="width: 10px; min-width: 10px;")

                            v-toolbar-title(style="width: 50px; min-width: 50px;")
                                v-btn(small color="warning" dark :to="{ name: 'ticket_show', params: { ticket_key: ticket.ticket_key } }")
                                    v-icon mdi-circle-edit-outline

</template>

<script lang="ts">
    import Vue from "vue";

    // Components
    import CopyableText from "@/components/shared/copyable_text.vue";
    import TooltipText from "@/components/shared/tooltip_text.vue";
    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Strike,
        Underline,
        // Code,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        // HorizontalRule,
        // History,
    } from "tiptap-vuetify";

    export default Vue.extend({
        data() {
            return {
                extensions: [
                    Blockquote,
                    Link,
                    Underline,
                    Strike,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList,
                    [
                        Heading,
                        {
                            options: {
                                levels: [1, 2, 3],
                            },
                        },
                    ],
                    Bold,
                    Paragraph,
                    HardBreak,
                ],
            };
        },
        computed: {
            toolbarBackground() {
                return process.env.VUE_APP_VOLIE_DETAILS_COMPONENT_TOOLBAR_BACKGROUND_IMG;
            },
            validTicket() {
                return this.ticket?.ticket_key?.length > 0;
            },
        },
        props: {
            editable: Boolean,
            ticket: Object,
            showSnackbar: Function,
        },
        components: {
            CopyableText,
            TiptapVuetify,
            TooltipText,
        },
    });
</script>

<style lang="scss" scoped>
</style>
