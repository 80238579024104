import { get, post, put } from '.';

export function CreateCampaignInheritance(options = {}) {
	return post<any>(`/app/api/campaign_inheritances`, options);
}

export function CreateCampaignInheritancesBatch(options = {}) {
	return post<any>(`/app/api/campaign_inheritances/batch`, options);
}

export function FindCampaignInheritance(campaignID: number) {
	return get<any>(`/app/api/campaigns/${campaignID}/campaign_inheritances`);
}

export function UpdateCampaignInheritance(campaignID: number, options = {}) {
    return put<any>(`/app/api/campaigns/${campaignID}/campaign_inheritances`, options);
}

export function GetAvailableCampaignInheritances(options = {}) {
	return get<any>(`/app/api/campaign_inheritances/available`, options);
}

export function GetParentCampaignsForAccount(options = {}) {
	return get<any>('/app/api/campaign_inheritances/parent_campaigns_for_account', options)
}

export function FindChildCampaignInheritancesForParent(options = {}) {
	return get<any>('/app/api/campaign_inheritances/child_campaigns_for_parent', options)
}
